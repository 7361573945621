import React, { useEffect } from 'react';
import { graphql } from "gatsby"

import { QueryFragments } from "../GraphQl/queryFragments" // eslint-disable-line

import { PortableTextBlock } from '../components/sanity/portableTextBlock';

// Hooks, Querys & State
import { useAppState } from "../state/appState"

// Components
import { PageMeta } from "../components/pageMeta"

const PostTemplate = ({ data: { page } }) => {
  
  const { meta, title, text, author, authorRole, date } = page || {};

  const { toggleHeaderLight, setHideFooter } = useAppState();

  useEffect(() => {
    toggleHeaderLight(false);
  }, [toggleHeaderLight]);

  useEffect(() => {
    setHideFooter(false);
  }, [setHideFooter]);
  
  return (
    <>
      <PageMeta {...meta} />
      <div className="px-gutter mt-40 md:mt-64">
        <div className="w-full mx-auto max-w-textBlock">
          {date && (
            <p className="font-display">{date}</p>
          )}
          <h1 className="text-3xl md:text-4xl lg:text-5xl font-display text-brand">
            {title && title}
          </h1>
          {author && (
            <>
              <h4 className="font-display mt-4">By {author}</h4>
              {authorRole && <h5 className="mt-1">{authorRole}</h5>}
            </>
          )}
        </div>
      </div>
      <div className="px-gutter my-20 lg:mb-64">
        {text && (
          <PortableTextBlock className={`prose xl:prose-lg mx-auto max-w-textBlock`} text={text} />
        )}
      </div>
    </>
  );
}

export default PostTemplate;

export const pageQuery = graphql`
  query postQuery($slug: String!) {
    page: sanityPost(slug: { current: { eq: $slug } }) {
      title
      author
      authorRole
      date(formatString: "d MMM Y")
      text: _rawText
      meta {
        ...MetaCardFields
      }
    }
  }
`;
